var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('employee.tabInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 pt-1"
        }, [_c('b-form', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Username",
            "rules": "required|min:4|userName|isUnique:employee,username",
            "mode": "eager"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "username"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.userName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "username",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "16",
                  "debounce": 300,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('employee.placeholderUsername')
                },
                model: {
                  value: _vm.employeeDataToAdd.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "username", $$v);
                  },
                  expression: "employeeDataToAdd.username"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "emailAddress"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.email')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "emailAddress",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "50",
                  "debounce": 300,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('employee.placeholderEmail')
                },
                model: {
                  value: _vm.employeeDataToAdd.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "emailAddress", $$v);
                  },
                  expression: "employeeDataToAdd.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Last Name",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "lastName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.lastName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "lastName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('employee.placeholderLastName')
                },
                model: {
                  value: _vm.employeeDataToAdd.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "lastName", $$v);
                  },
                  expression: "employeeDataToAdd.lastName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "First Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "firstName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.firstName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "firstName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('employee.placeholderFirstName')
                },
                model: {
                  value: _vm.employeeDataToAdd.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "firstName", $$v);
                  },
                  expression: "employeeDataToAdd.firstName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Phone",
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "phoneNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.phoneNumber')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "phoneNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('employee.placeholderPhoneNumber')
                },
                model: {
                  value: _vm.employeeDataToAdd.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "phoneNumber", $$v);
                  },
                  expression: "employeeDataToAdd.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Gender",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "gender",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.gender')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": _vm.genderOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "gender",
                  "placeholder": _vm.$t('employee.placeholderSelectGender')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn(_ref2) {
                    var search = _ref2.search,
                      searching = _ref2.searching,
                      loading = _ref2.loading;
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  }
                }], null, true),
                model: {
                  value: _vm.employeeDataToAdd.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "gender", $$v);
                  },
                  expression: "employeeDataToAdd.gender"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Employee Type",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "type",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.employeeType')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": _vm.isADM ? _vm.typeOfEmployeeOptions : _vm.typeOfEmployeeOptionsKhacADM,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "type",
                  "placeholder": _vm.$t('employee.placeholderSelectType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeDataToAdd.type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "type", $$v);
                  },
                  expression: "employeeDataToAdd.type"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Agency",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.agencies')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.agenciesManagerList,
                  "reduce": function reduce(val) {
                    return val.id;
                  },
                  "searchable": "",
                  "filterable": false,
                  "input-id": "agency",
                  "label": "agencyName",
                  "placeholder": _vm.$t('placeholderSelect'),
                  "disabled": true
                },
                on: {
                  "open": _vm.openAgenciesManager,
                  "search": _vm.searchAgenciesManager
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.agencyName) + " ")]), _c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref3) {
                    var loading = _ref3.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyManager,
                  callback: function callback($$v) {
                    _vm.agencyManager = $$v;
                  },
                  expression: "agencyManager"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.employeeDataToAdd.type === 'BE' ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.bookerType'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "booker-type",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.bookerType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.flightTypeOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": true,
                  "input-id": "booker-type",
                  "placeholder": _vm.$t('employee.placeholderSelectType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeDataToAdd.bookerType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "bookerType", $$v);
                  },
                  expression: "employeeDataToAdd.bookerType"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), ['BE', 'SE'].includes(_vm.employeeDataToAdd.type) && _vm.isF1 ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.employeeDataToAdd.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "scheduleCode"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.employeeDataToAdd.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "scheduleCode",
                  "number": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "name": _vm.employeeDataToAdd.type === 'BE' ? _vm.$t('employee.scheduleCodeBE') : _vm.$t('employee.scheduleCodeSE'),
                  "lazy-formatter": "",
                  "placeholder": _vm.$t('employee.placeholderScheduleCode')
                },
                model: {
                  value: _vm.employeeDataToAdd.scheduleCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "scheduleCode", $$v);
                  },
                  expression: "employeeDataToAdd.scheduleCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _vm.isADM && _vm.isF1 && _vm.employeeDataToAdd.type === 'BE' ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.bookerId'),
            "rules": "bookerId|min:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookerId"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('employee.bookerId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookerId",
                  "name": _vm.$t('employee.bookerId'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('employee.placeholderBookerId')
                },
                model: {
                  value: _vm.employeeDataToAdd.bookerId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToAdd, "bookerId", $$v);
                  },
                  expression: "employeeDataToAdd.bookerId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-1",
          attrs: {
            "variant": "danger",
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('employee.clear')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": invalid || !_vm.canAccess('employee.createEmployee')
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.nextHandle);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }